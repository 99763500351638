var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-manage" }, [
      _c("div", { attrs: { id: "blog-topImg" } }),
      _c("div", { staticClass: "blog-title-text mt-5 text-center" }, [
        _vm._v("笠置山｜新しいトポ追加のお知らせ")
      ]),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "text-center blog-content-text" }, [
          _c("div", { staticClass: "mt-3" }, [_vm._v("2021/01/15")]),
          _c("div", { staticClass: "mt-3 text-left" }, [
            _c("p"),
            _c("p", [
              _vm._v("新年あけましておめでとうございます。ヤマラボ管理人です。")
            ]),
            _c("p"),
            _c("p", [_vm._v("昨年は大変お世話になりました。")]),
            _c("p"),
            _c("p", [
              _vm._v(
                "2021年も、クライマーの皆様に安心のサービスをご提供できるよう"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("より一層サービス向上に努めてまいります。")]),
            _c("p"),
            _c("p", [_vm._v("明るく楽しい一年でありますように、")]),
            _c("p"),
            _c("p", [_vm._v("今年もよろしくお願いいたします。")]),
            _c("p"),
            _c("p", [_vm._v("そして、新しいトポ追加のお知らせです！")]),
            _c("p"),
            _c("p", [_vm._v("岐阜県にある、笠置山のトポを追加しました。")]),
            _c("p"),
            _c("img", {
              attrs: {
                src: "/img/blog/20210115/kasagi1.png",
                width: "300px",
                height: "125px",
                alt: ""
              }
            }),
            _c("img", {
              attrs: {
                src: "/img/blog/20210115/kasagi2.png",
                width: "300px",
                height: "125px",
                alt: ""
              }
            }),
            _c("p"),
            _c("p", [_c("strong", [_vm._v("・大岩ごけエリア")])]),
            _c("p", [_c("strong", [_vm._v("・電波塔尾根エリア")])]),
            _c("p", [_c("strong", [_vm._v("・テンプテーションA・Bエリア")])]),
            _c("p", [_c("strong", [_vm._v("・ルートクライミングエリア")])]),
            _c("p"),
            _c("p", [_vm._v("上記、4か所のトポを追加しました。")]),
            _c("p"),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "https://www.yamalab.jp/rocky/rocky_list?style=&regiones=&keyword=%E7%AC%A0%E7%BD%AE%E5%B1%B1",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("こちら")]
              ),
              _vm._v("から、岩の確認ができます。")
            ]),
            _c("p"),
            _c("p", [
              _vm._v(
                "今回、上記4か所のエリアの中でもトポ追加されていない岩があります。"
              )
            ]),
            _c("p"),
            _c("p", [_vm._v("ですが、今後も笠置山に調査に行く予定なので、")]),
            _c("p"),
            _c("p", [_vm._v("トポ追加でき次第、こちらでお知らせいたします！")]),
            _c("p"),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※上記4つの岩場トポは有料プラン限定です")
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※ユーザー登録お済でない方は"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.yamalab.jp/register_user/send_auth",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("こちら")]
              ),
              _vm._v("から")
            ]),
            _c("p", { staticClass: "text-danger" }, [
              _vm._v("※有料プランへ切替は"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.yamalab.jp/user/payment/add_input",
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [_vm._v("こちら")]
              ),
              _vm._v("から")
            ]),
            _c("p"),
            _c("p", [_vm._v("～笠置山調査のハイライト～")]),
            _c("p"),
            _c("p", [_vm._v("調査中に遭遇した")]),
            _c("p"),
            _c("p", [_vm._v("森の妖精")]),
            _c("p"),
            _c("img", {
              staticClass: "blogImg",
              attrs: { src: "/img/blog/20210115/DSCN2793.JPG", alt: "" }
            }),
            _c("p"),
            _c("p", [_vm._v("かわいい！(笑)")]),
            _c("p")
          ]),
          _c("hr", {
            staticClass: "mt-5",
            attrs: { color: "#CCCCCC", width: "100%", size: "3" }
          }),
          _c("a", { staticClass: "d-inline-block", attrs: { href: "/blog" } }, [
            _c("div", { staticClass: "mt-5 blog-button px-4 py-1" }, [
              _vm._v("ブログ一覧")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }